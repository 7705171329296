const config = (
  state = {
    articleFilters: new Set(),
    fighterFilters: new Set(),
  },
  action
) => {
  switch(action.type) {
    case 'SET_STATE_VALUE':
      return Object.assign({}, state, action.payload);
    case 'TOGGLE_BOOLEAN_KEY':
      return Object.assign({}, state, {[action.payload["key"]]: !state[action.payload["key"]]});
    default:
      return state;
  }
}

export default config;
