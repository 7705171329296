import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import config from './reducers';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const createStore = () => reduxCreateStore(config, composeEnhancers(applyMiddleware(thunk)));

export default createStore;
